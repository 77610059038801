import React, { useContext, useEffect } from 'react';
import { Box, Grid, Icon, Switch, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { summarizer } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import Container from 'components/Container';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import styles from './styles';
import moment from 'moment';

const Devolução = () => {
  const { documento_id } = useParams();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumentos, postLoading, getDocumento, getLoading, documento } =
    useContext(DocumentosContext);
  const defaultValues = {
    gera_garantia: false,
    natureza_operacao_id: 4,
    observacao: '',
    itens: [],
  };
  const navigate = useNavigate();
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });

  const renderValues = () => {
    const itens = documento?.DocumentoItems?.map((item) => {
      const gerados = item?.Vinculos?.filter(
        (d) =>
          d?.especie_id === documento?.especie_id &&
          (d?.natureza_operacao_id === 3 || d?.natureza_operacao_id === 4)
      );
      const quantidade = item?.quantidade - summarizer(gerados, 'quantidade');
      item['quantidade_max'] = quantidade;
      item['quantidade'] = quantidade;
      item['preco'] = item?.preco;
      item['calcimp'] = 'SIM';
      item['Vinculos'] = [{ documento_item_id_vinculo: item?.id }];
      item['subtotal'] =
        (item?.quantidade || 0) * (item?.preco || 0) +
        (item?.desconto_vlr || 0) +
        (item?.tributos || 0) +
        (item?.despac || 0) +
        (item?.frete || 0) +
        (item?.eventuais || 0) +
        (item?.outros || 0) +
        (item?.seguro || 0);
      item['Estruturas'] = item?.DocumentoItemEstruturas?.map((e) => {
        delete e?.Produto;
        delete e?.documento_item_id;
        delete e?.id;
        return e;
      });
      delete item?.Cemovimentos;
      delete item?.Ceexpedicaos;
      delete item?.Cfo;
      delete item?.Destinos;
      delete item?.DocumentoItemAprovacaos;
      delete item?.DocumentoItemEstruturas;
      delete item?.DocumentoItemImpostos;
      delete item?.DocumentoItemMc;
      delete item?.DocumentoItemOrigems;
      delete item?.DocumentoItemProcessos;
      delete item?.DocumentoItemQualidades;
      delete item?.DocumentoItemVariacaos;
      delete item?.Kit;
      delete item?.Produto;
      delete item?.UnidadeMedida;
      delete item?.Vinculados;
      delete item?.Vinculos;
      delete item?.cfo_id;
      delete item?.documento_id;
      return item;
    })?.filter((f) => Boolean(f?.quantidade));
    setValue('itens', itens);
  };

  useEffect(() => {
    if (Boolean(documento_id)) {
      getDocumento(documento_id);
    }
  }, []);

  useEffect(() => {
    if (Boolean(documento_id) && Boolean(documento)) {
      renderValues();
    }
  }, [documento]);

  const onCellEditCommit = (e) => {
    const itens = watch('itens')?.map((c) => {
      if (c?.id === e?.id) {
        if (e?.field === 'quantidade' && e?.value > c?.quantidade_max) {
          const subtotal =
            ((c?.subtotal || 0) / (c?.quantidade || 0)) *
            (c?.quantidade_max || 0);
          return { ...c, [e?.field]: c?.quantidade_max, subtotal };
        } else {
          const subtotal =
            ((c?.subtotal || 0) / (c?.quantidade || 0)) * (e?.value || 0);
          return { ...c, [e?.field]: e?.value, subtotal };
        }
      } else {
        return { ...c };
      }
    });
    setValue('itens', itens);
  };

  const onDeleteItem = (id) => {
    setValue(
      'itens',
      watch('itens')?.filter((i) => i?.id !== id)
    );
  };

  const onSubmit = (values) => {
    const Documento = {
      especie_id: documento?.especie_id,
      natureza_operacao_id: values?.natureza_operacao_id,
      users_id: user?.id,
      filial_id: documento?.filial_id,
      cadastro_id: documento?.cadastro_id,
      dtemissao: values?.dtemissao,
      celocal_id: documento?.celocal_id,
      contribuinte_id: documento?.contribuinte_id,
      usofinal: documento?.usofinal,
      observacao: values?.observacao,
    };

    const Saida = {
      condicao_pagamento_id:
        drops?.CondicaoPagamento?.find(
          (c) =>
            c?.nvezes === 1 &&
            c?.modulo === 'VENDAS' &&
            (!c?.intervalop || !c?.intervalo)
        )?.value || 1,
      vendedor_id: documento?.DocumentoSaida?.vendedor_id,
      documento: documento?.DocumentoSaida?.documento,
      validade: documento?.DocumentoSaida?.validade,
      dtsaida: documento?.DocumentoSaida?.dtsaida,
      dtatend: documento?.DocumentoSaida?.dtatend,
      desc1: documento?.DocumentoSaida?.desc1,
      desc2: documento?.DocumentoSaida?.desc2,
      desc3: documento?.DocumentoSaida?.desc3,
      desc4: documento?.DocumentoSaida?.desc4,
      desc5: documento?.DocumentoSaida?.desc5,
    };

    const Itens = values?.itens?.map((item, index) => {
      item['Vinculos'] = [{ documento_item_id_vinculo: item?.id }];
      item['Origens'] = [{ documento_item_org_id: item?.id }];
      delete item?.id;
      return { ...item, sequencia: index + 1 };
    });

    const Financeiros = [
      {
        forma_pagto_id:
          drops?.FormaDePagamento?.find(
            (f) =>
              f?.indpag === '0-A VISTA' &&
              f?.modalidade === '01-DINHEIRO' &&
              f?.modulo === 'VENDAS'
          )?.value || 1,
        parcela: 1,
        dtvenc: moment().format('YYYY-MM-DD'),
        valor: summarizer(Itens, 'subtotal') || 0,
      },
    ];

    const Origens = Boolean(documento_id)
      ? Documento?.especie_id === 16
        ? [{ documento_id, status_id: 21 }]
        : [{ documento_id }]
      : [];

    const data = [{ Documento, Saida, Financeiros, Itens, Origens }];

    if (Boolean(values?.gera_garantia)) {
      data?.push({
        Documento: { ...Documento, especie_id: 46, natureza_operacao_id: 54 },
        Origens,
        Saida,
        Itens: Itens?.map(({ Vinculos, ...rest }) => rest),
      });
    }

    postDocumentos({ data, cb: () => navigate(-1) });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Devolução" />
      <Card title="Itens">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataGrid
              rows={watch('itens')}
              columns={[
                {
                  field: 'referencia',
                  headerName: 'Referência',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'descricao',
                  headerName: 'Descrição',
                  flex: 2,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  flex: 1,
                  type: 'number',
                  sortable: false,
                  editable: true,
                },
                {
                  field: 'desconto_vlr',
                  headerName: 'Desconto',
                  type: 'number',
                  valueGetter: ({ value }) =>
                    (value || 0)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'preco',
                  headerName: 'Preço Unitário',
                  type: 'number',
                  valueGetter: ({ value }) =>
                    (value || 0)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'subtotal',
                  headerName: 'Subtotal',
                  type: 'number',
                  valueGetter: ({ value }) =>
                    (value || 0)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  type: 'actions',
                  flex: 0,
                  getActions: ({ id }) => [
                    <GridActionsCellItem
                      icon={<Icon>delete</Icon>}
                      label="Excluir"
                      onClick={() => onDeleteItem(id)}
                    />,
                  ],
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              onCellEditCommit={onCellEditCommit}
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" align="center">
              Total:{' '}
              {(summarizer(watch('itens'), 'subtotal') || 0)?.toLocaleString(
                'pt-br',
                {
                  style: 'currency',
                  currency: 'BRL',
                }
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Informações Adicionais" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Switch
              checked={Boolean(watch('natureza_operacao_id') === 4)}
              onChange={(_, v) => setValue('natureza_operacao_id', v ? 4 : 3)}
            />
            <Typography>Movimentar estoque</Typography>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Switch
              checked={watch('gera_garantia')}
              onChange={(_, v) => setValue('gera_garantia', v)}
            />
            <Typography>Gerar garantia</Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Box textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Box>
    </Container>
  );
};

export default Devolução;

import { AuthProvider } from 'contexts/AuthContext';
import { NotificaçõesProvider } from 'contexts/NotificaçõesContext';
import { GridProvider } from 'contexts/GridContext';
import { DropsProvider } from 'contexts/DropsContext';
import { VendaProvider } from 'contexts/VendaContext';
import { OrçamentosProvider } from 'contexts/OrçamentosContext';
import { PedidosProvider } from 'contexts/PedidosContext';
import { NotasProvider } from 'contexts/NotasContext';
import { ContratosProvider } from 'contexts/ContratosContext';
import { ProdutosProvider } from 'contexts/ProdutosContext';
import { RemessasProvider } from 'contexts/RemessasContext';
import { VendaExternaProvider } from 'contexts/VendaExternaContext';
import { DemonstracaoProvider } from 'contexts/DemonstraçãoContext';
import { IndustrializacaoProvider } from 'contexts/IndustrializaçãoContext';
import { ExposicaoFeiraProvider } from 'contexts/ExposiçãoFeiraContext';
import { ConsertoReparoProvider } from 'contexts/ConsertoReparoContext';
import { ComodatoProvider } from 'contexts/ComodatoContext';
import { EntregasProvider } from 'contexts/EntregasContext';
import { TrocaProvider } from 'contexts/TrocaContext';
import { GarantiaProvider } from './GarantiaContext';
import { GarantiasProvider } from 'contexts/GarantiasContext';
import { DocumentosProvider } from 'contexts/DocumentosContext';
import { DashboardProvider } from 'contexts/DashboardContext';
import { RelatoriosProvider } from 'contexts/RelatoriosContext';
import {
  DialogProvider,
  ModalProvider,
  DrawerProvider,
} from 'components/Modals';

const Providers = ({ children }) => (
  <AuthProvider>
    <NotificaçõesProvider>
      <GridProvider>
        <DropsProvider>
          <RelatoriosProvider>
            <DashboardProvider>
              <DocumentosProvider>
                <ProdutosProvider>
                  <VendaProvider>
                    <OrçamentosProvider>
                      <PedidosProvider>
                        <NotasProvider>
                          <ContratosProvider>
                            <RemessasProvider>
                              <VendaExternaProvider>
                                <DemonstracaoProvider>
                                  <IndustrializacaoProvider>
                                    <ExposicaoFeiraProvider>
                                      <ConsertoReparoProvider>
                                        <ComodatoProvider>
                                          <EntregasProvider>
                                            <TrocaProvider>
                                              <GarantiaProvider>
                                                <GarantiasProvider>
                                                  <ModalProvider>
                                                    <DialogProvider>
                                                      <DrawerProvider>
                                                        {children}
                                                      </DrawerProvider>
                                                    </DialogProvider>
                                                  </ModalProvider>
                                                </GarantiasProvider>
                                              </GarantiaProvider>
                                            </TrocaProvider>
                                          </EntregasProvider>
                                        </ComodatoProvider>
                                      </ConsertoReparoProvider>
                                    </ExposicaoFeiraProvider>
                                  </IndustrializacaoProvider>
                                </DemonstracaoProvider>
                              </VendaExternaProvider>
                            </RemessasProvider>
                          </ContratosProvider>
                        </NotasProvider>
                      </PedidosProvider>
                    </OrçamentosProvider>
                  </VendaProvider>
                </ProdutosProvider>
              </DocumentosProvider>
            </DashboardProvider>
          </RelatoriosProvider>
        </DropsProvider>
      </GridProvider>
    </NotificaçõesProvider>
  </AuthProvider>
);

export default Providers;

import moment from 'moment';
import CryptoJS from 'crypto-js';
import api from 'services/api';
import LogoMercadoLivre from 'assets/mercado_livre.svg';
import LogoMercadoLivreFull from 'assets/mercado_livre_full.svg';

export const findOnArray = (value, arr = [], field) => {
  const rec = arr.find((i) => i.value === value);
  if (field) {
    return rec?.[field] || null;
  }
  return rec || null;
};

export const summarizer = (arr = [], field) =>
  arr?.length &&
  Number(
    arr
      ?.map((i) => i[field])
      ?.reduce((prev, next) => prev + next)
      ?.toFixed(2)
  );

export const checkInfosProduto = async ({ produto_id, ...rest }) => {
  const { data } = await api.get(`/Cadastros/Produto/${produto_id}`, {
    params: { ...rest, Midias: true, Descricao: true },
  });
  const imagem = data?.ProdutoImagems?.find((f) => f?.Midium?.tipo === 'IMAGEM')
    ?.Midium?.url;
  const descricao = data?.ProdutoDescricaos?.find(
    (f) => f?.tipo === 'COMPLETA' || f?.tipo === 'REDUZIDA'
  )?.descricao;
  return { ...data, imagem, descricao };
};

export const openXML = (xml) => {
  const blob = new Blob([xml], { type: 'text/xml' });
  const url = URL.createObjectURL(blob);
  window.open(url);
  URL.revokeObjectURL(url);
};

export const getDatePagto = (values) => {
  let start_date = moment(values?.start_date).format('YYYY-MM-DD');
  let count_fora = 1;
  if (values?.entrada === 'SIM') {
    count_fora = 2;
    start_date = moment(start_date).add(1, 'day').format('YYYY-MM-DD');
    if (values?.parcela === 1) {
      return start_date;
    }
  }
  if (values?.fora === 'SEMANA') {
    start_date = moment(start_date).add(1, 'week').format('YYYY-MM-DD');
    if (values?.parcela === count_fora) {
      return start_date;
    }
  }
  if (values?.fora === 'QUINZENA') {
    start_date = moment(start_date).add(15, 'days').format('YYYY-MM-DD');
    if (values?.parcela === count_fora) {
      return start_date;
    }
  }
  if (values?.fora === 'MES') {
    start_date = moment(start_date).add(1, 'month').format('YYYY-MM-DD');
    if (values?.parcela === count_fora) {
      return start_date;
    }
  }

  if (Boolean(values?.intervalop >= 0)) {
    start_date = moment(start_date)
      .add(values?.intervalop, 'days')
      .format('YYYY-MM-DD');
    if (values?.parcela !== 1) {
      start_date = moment(start_date)
        .add(values?.intervalo * (values?.parcela - 1), 'days')
        .format('YYYY-MM-DD');
    }
  } else {
    start_date = moment(start_date)
      .add(values?.intervalo * values?.parcela, 'days')
      .format('YYYY-MM-DD');
  }

  if (values?.fixar && values?.intervalo % 30 === 0) {
    const diaFixo = moment(values?.start_date).format('DD');
    start_date = moment(start_date).set('D', diaFixo).format('YYYY-MM-DD');
  }

  if (Boolean(values?.dia)) {
    while (Number(moment(start_date).format('DD')) !== values?.dia) {
      start_date = moment(start_date).add(1, 'day').format('YYYY-MM-DD');
    }
  }
  return start_date;
};

export const renderLogo = (value) => {
  const style = { objectFit: 'contain', width: '100%', height: '100%' };
  switch (value) {
    case 'MERCADO LIVRE':
      return <img src={LogoMercadoLivre} style={style} />;
    case 'MERCADO LIVRE fullfilment':
      return <img src={LogoMercadoLivreFull} style={style} />;
    default:
      return value;
  }
};

export const encryptURL = (word, key = 'E-Solution') => {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
};

export const decryptURL = (word, key = 'E-Solution') => {
  let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
  let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8);
  try {
    const a = JSON.parse(bytes);
    return a;
  } catch (error) {
    return null;
  }
};

export const removeFalseKeys = (obj) => {
  Object.keys(obj).map((key) => {
    if (
      typeof obj[key] === 'object' &&
      Boolean(obj[key]) &&
      Object.keys(obj[key]).length > 0
    ) {
      removeFalseKeys(obj[key]);
    } else {
      if (
        (!Boolean(obj[key]) && obj[key] !== 0) ||
        (Object.keys(obj[key]).length === 0 && typeof obj[key] === 'object') ||
        obj[key] === 'NaN'
      ) {
        delete obj[key];
      }
    }
  });
};

export const filterSearch = (data = [], value = '') =>
  data?.filter((f) => {
    return Object.keys(f).some(
      (key) =>
        (f[key] || '')
          ?.toString()
          ?.toLowerCase()
          ?.indexOf(value?.toLowerCase()) !== -1
    );
  });

import { createContext, useState } from 'react';
import api from 'services/api';
import moment from 'moment';

const filter = {
  like: '',
  id: '',
  status: [],
  situacao: [],
  natureza_operacao_id: [],
  dtemissao: {
    i: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    f: null,
  },
  documento: '',
  entidade: [],
  vldocto: '',
};

export const TrocaContext = createContext();

export const TrocaProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [troca, setTroca] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtemissao', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });

  const getTroca = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: troca?.filter,
        page: troca?.page,
        size: troca?.size,
        order: troca?.order,
        ...payload,
      });
      setTroca((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <TrocaContext.Provider
      value={{
        troca,
        getLoading,
        getTroca,
      }}
    >
      {children}
    </TrocaContext.Provider>
  );
};

import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { NotasContext } from 'contexts/NotasContext';
import { GridContext } from 'contexts/GridContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useModal, useDialog } from 'components/Modals';
import ImportaXMLModal from 'components/Modals/ImportaXMLModal';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';
import ImpExpModal from './Modals/ImpExpModal';
import TransmissãoModal from './Modals/TransmissãoModal';
import InutilizaçãoModal from './Modals/InutilizaçãoModal';
import InutilizadosModal from './Modals/InutilizadosModal';

const Notas = () => {
  const rotina = 'Notas';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { openDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { notas, getNotas, getLoading, exportXML, exportXMLAll } =
    useContext(NotasContext);
  const { postDocumentos } = useContext(DocumentosContext);
  const { control, getValues, reset } = useForm({
    defaultValues: notas?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getNotas({ ...props, rotina, filter: getValues() });
  };

  const impExpAction = ({ value, selected }) => {
    switch (value) {
      case '1':
        if (Boolean(selected?.length)) {
          postDocumentos({
            data: selected?.map((d) => ({
              Emitir: { id: d?.id, tpamb: 1, simplificado: true },
            })),
            cb: loadGrid,
          });
        } else {
          toastWarning('Selecione ao menos um item');
        }
        break;
      case '2':
        if (Boolean(selected?.length)) {
          navigate('/app/Notas/Etiquetas', {
            state: { documentos: selected },
          });
        } else {
          toastWarning('Selecione ao menos um item');
        }
        break;
      case '3':
        if (Boolean(selected?.length)) {
          exportXML({
            data: { notas: selected?.map((s) => s?.id), danfe: false },
            cb: loadGrid,
          });
        } else {
          toastWarning('Selecione ao menos um item');
        }
        break;
      case '4':
        if (Boolean(selected?.length)) {
          exportXML({
            data: { notas: selected?.map((s) => s?.id), danfe: true },
            cb: loadGrid,
          });
        } else {
          toastWarning('Selecione ao menos um item');
        }
        break;
      case '5':
        exportXMLAll({
          data: { rotina, filter: getValues(), danfe: false },
          cb: loadGrid,
        });
        break;
      case '6':
        exportXMLAll({
          data: { rotina, filter: getValues(), danfe: true },
          cb: loadGrid,
        });
        break;
      case '7':
        setTimeout(() => openModal(<InutilizadosModal />), 500);
        break;

      default:
        break;
    }
  };

  const options = [
    {
      name: 'Adicionar Nota',
      icon: 'add',
      action: () => navigate('/app/Notas/Gerar/NF'),
    },
    {
      name: 'Adicionar Fatura',
      icon: 'add',
      action: () => navigate('/app/Notas/Gerar/Fatura'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: notas?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: notas?.order }),
    },
    // {
    //   name: 'Exportar XML',
    //   icon: 'file_download',
    //   action: (selected) => {
    //     if (!Boolean(selected?.length)) {
    //       exportXMLAll({
    //         data: { rotina, filter: getValues(), danfe: false },
    //         cb: loadGrid,
    //       });
    //     } else {
    //       exportXML({
    //         data: { notas: selected?.map((s) => s?.id), danfe: false },
    //         cb: loadGrid,
    //       });
    //     }
    //   },
    // },
    {
      name: 'Impressões / Exportações',
      icon: 'print',
      action: (selected) =>
        openModal(<ImpExpModal onSubmit={impExpAction} selected={selected} />),
    },
    // {
    //   name: 'Exportar XML Inutilizados',
    //   icon: 'file_download',
    //   action: () => openModal(<InutilizadosModal />),
    // },
    {
      name: 'Importar XML',
      icon: 'file_upload',
      action: () =>
        openDialog(
          <ImportaXMLModal callback={loadGrid} />,
          'Importação de Arquivos XML'
        ),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={notas?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Transmitir / Registrar Notas',
      icon: 'send',
      action: (selected) => {
        const notas = selected?.filter((f) =>
          Boolean(f?.status !== 'CONCLUIDO')
        );
        if (!Boolean(notas?.length)) {
          return toastWarning('Selecione apenas documentos não concluídos');
        } else {
          openModal(<TransmissãoModal selected={notas} callback={loadGrid} />);
        }
      },
    },
    {
      name: 'Inutilizar Numeração',
      icon: 'block',
      action: () => openModal(<InutilizaçãoModal callback={loadGrid} />),
    },
    // {
    //   name: 'Etiquetas Destinatário',
    //   icon: 'print',
    //   action: (documentos) => {
    //     if (Boolean(documentos?.length)) {
    //       navigate('/app/Notas/Etiquetas', { state: { documentos } });
    //     } else {
    //       toastWarning('Selecione ao menos um item');
    //     }
    //   },
    // },
    // {
    //   name: 'DANFE Simplificada',
    //   icon: 'print',
    //   action: (documentos) => {
    //     if (Boolean(documentos?.length)) {
    //       postDocumentos({
    //         data: documentos?.map((d) => ({
    //           Emitir: { id: d?.id, tpamb: 1, simplificado: true },
    //         })),
    //         cb: loadGrid,
    //       });
    //     } else {
    //       toastWarning('Selecione ao menos um item');
    //     }
    //   },
    // },
    {
      name: 'Gerar MDFE',
      icon: 'post_add',
      action: (documentos) => {
        if (Boolean(documentos?.length)) {
          const check = Boolean(
            documentos?.every(
              (s) => s?.status === 'CONCLUIDO' && s?.modelo === 'NFe'
            )
          );
          if (check) {
            navigate('/app/Notas/Gerar/MDFE', { state: { documentos } });
          } else {
            toastWarning(
              'Selecione somente Notas Fiscais eletrônicas concluídas'
            );
          }
        } else {
          toastWarning('Selecione ao menos um item');
        }
      },
    },
  ];
  const rowOptions = [
    {
      name: 'Aprovação necessária',
      icon: 'priority_high',
      color: 'secondary.main',
      show: ({ row }) => Boolean(row?.aprovacao),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.id}`),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} colunas={notas?.colunas} />
      <Card>
        <Grid
          grid={notas}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeSearch={false}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={notas} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Notas;

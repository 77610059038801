import React, { useContext } from 'react';
import { DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { NotasContext } from 'contexts/NotasContext';
import { DropsContext } from 'contexts/DropsContext';
import { VendaContext } from 'contexts/VendaContext';
import { useModal } from 'components/Modals';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Input from 'components/Input';

const InutilizaçãoModal = ({ callback }) => {
  const { closeModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { postInutilizacao, postLoading } = useContext(NotasContext);
  const { contas } = useContext(VendaContext);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      filial_id: null,
      serie: null,
      inicial: '',
      final: '',
      justificativa: '',
    },
  });

  const onSubmit = (data) => {
    postInutilizacao({
      data,
      cb: () => {
        closeModal();
        if (callback) {
          callback();
        }
      },
    });
  };

  return (
    <>
      <DialogTitle>Inutilizar Numeração</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="serie"
              control={control}
              label="Série"
              options={drops?.Serie?.filter(
                (f) =>
                  !Boolean(f?.cxconta_id) ||
                  f?.cxconta_id === contas?.cxconta_id
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="inicial" control={control} label="Nº Inicial" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="final" control={control} label="Nº Final" />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="justificativa"
              control={control}
              label="Justificativa"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)} loading={postLoading}>
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default InutilizaçãoModal;

import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { GridContext } from 'contexts/GridContext';
import { toastWarning } from 'utils/toast';
import { DropsContext } from 'contexts/DropsContext';
import { EntregasContext } from 'contexts/EntregasContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import TransmissãoModal from 'views/Notas/Modals/TransmissãoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Entregas = () => {
  const rotina = 'Entregas';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { entregas, getEntregas, getLoading } = useContext(EntregasContext);
  const { control, getValues, reset } = useForm({
    defaultValues: entregas?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getEntregas({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: entregas?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: entregas?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={entregas?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Transmitir / Registrar Notas',
      icon: 'send',
      action: (selected) => {
        const notas = selected?.filter((f) =>
          Boolean(f?.status !== 'CONCLUIDO')
        );
        if (!Boolean(notas?.length)) {
          return toastWarning('Selecione apenas documentos não concluídos');
        } else {
          openModal(<TransmissãoModal selected={notas} callback={loadGrid} />);
        }
      },
    },
  ];

  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.id}`),
    },
    {
      name: 'Realizar Entrega',
      icon: 'local_shipping',
      show: ({ row }) => Boolean(row?.natureza_operacao === 30),
      action: ({ row }) =>
        navigate(`/app/Remessas/Gerar/${row?.especie}/31/[]/${row?.id}`),
      menu: true,
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} colunas={entregas?.colunas} />
      <Card>
        <Grid
          grid={entregas}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={entregas} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Entregas;

import React, { useEffect, useState, useRef } from 'react';
import {
  LinearProgress,
  Pagination,
  CircularProgress,
  Icon,
  Tooltip,
} from '@mui/material';
import {
  DataGrid,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { getActiveFilter, joinColumns } from 'utils/grid';
import GridFilter from 'components/GridFilter';
import GridToolbar from 'components/GridToolbar';
import localeText from './localeText';
import styles from './styles';

const CustomGrid = ({
  grid,
  mock,
  control,
  options,
  rowOptions = [],
  loadGrid,
  activeFilter,
  activeSearch = true,
  FilterComponent,
  ...props
}) => {
  const [selection, setSelection] = useState([]);
  const [fullSelection, setFullSelection] = useState([]);
  const [initialState, setInitialState] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const initialRender = useRef(null);

  const handleDrawerToggle = () => setOpenDrawer(!openDrawer);

  useEffect(() => {
    loadGrid();
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      let columnVisibilityModel = {};
      let sortModel = [];
      grid?.colunas?.map((c) => {
        if (!c?.visible) {
          columnVisibilityModel = {
            ...columnVisibilityModel,
            [c?.field]: false,
          };
        }
      });
      if (grid?.order) {
        sortModel.push(grid?.order);
      }
      setInitialState({
        columns: { columnVisibilityModel },
        sorting: { sortModel },
      });
    } else {
      initialRender.current = true;
    }
  }, [grid]);

  const getSize = () => {
    const hasMenu = rowOptions?.some((s) => s?.menu === true);
    const options = rowOptions?.filter((f) => !f?.menu)?.length || 1;
    const size = hasMenu ? 50 * options + 50 : 50 * options;
    return size > 100 ? size : 100;
  };

  const actionColumn = !!rowOptions?.length
    ? [
        {
          field: 'actions',
          headerName: 'Ações',
          type: 'actions',
          width: getSize(),
          getActions: (params) =>
            rowOptions
              ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
              ?.map(
                ({ icon, name, action, color, menu, show = () => true }) =>
                  show(params) && (
                    <GridActionsCellItem
                      icon={
                        menu ? (
                          <Icon sx={{ color }}>{icon}</Icon>
                        ) : (
                          <Tooltip title={name}>
                            <Icon sx={{ color }}>{icon}</Icon>
                          </Tooltip>
                        )
                      }
                      label={name}
                      showInMenu={menu}
                      onClick={() => action(params)}
                      disabled={!action}
                      sx={styles?.actionCell}
                    />
                  )
              )
              ?.filter(Boolean),
        },
      ]
    : [];

  if (!!initialState) {
    return (
      <>
        <GridToolbar
          control={control}
          options={options}
          activeSearch={activeSearch}
          onSubmit={() => loadGrid({ page: 0 })}
          activeFilter={activeFilter && activeFilter(getActiveFilter)}
          handleDrawerToggle={handleDrawerToggle}
          showFilterButton={!!FilterComponent}
          selection={fullSelection}
        />
        <DataGrid
          {...props}
          initialState={initialState}
          rows={grid?.data}
          columns={joinColumns([...actionColumn, ...grid?.colunas], mock)}
          pagination
          paginationMode="server"
          autoHeight
          rowCount={grid?.total || 0}
          pageSize={grid?.size}
          sortingMode="server"
          onSortModelChange={(sortModel) => loadGrid({ order: sortModel[0] })}
          selectionModel={selection}
          onSelectionModelChange={(ids) =>
            setSelection((prevIds) => {
              let diff = prevIds
                .filter((x) => !ids.includes(x))
                .concat(ids.filter((x) => !prevIds.includes(x)));
              diff?.map((d) => {
                if (prevIds?.some((s) => s === d)) {
                  setFullSelection((prev) => prev?.filter((f) => f?.id !== d));
                } else {
                  const select = grid?.data?.find((f) => f?.id === d);
                  if (select) {
                    setFullSelection((prev) => [...prev, select]);
                  }
                }
              });
              return ids;
            })
          }
          components={{
            LoadingOverlay: LinearProgress,
            Pagination: () => {
              const gridRef = useGridApiContext();
              const count = useGridSelector(gridRef, gridPageCountSelector);
              return (
                <Pagination
                  color="primary"
                  count={count}
                  page={grid?.page + 1}
                  onChange={(_, value) => loadGrid({ page: value - 1 })}
                />
              );
            },
          }}
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          keepNonExistentRowsSelected
          showCellRightBorder
          showColumnRightBorder
          localeText={localeText}
        />
        <GridFilter
          open={openDrawer}
          handleDrawerToggle={handleDrawerToggle}
          onSubmit={() => {
            loadGrid({ page: 0 });
            handleDrawerToggle();
          }}
          onReset={() => {
            loadGrid({ page: 0, replace: true });
            handleDrawerToggle();
          }}
        >
          {FilterComponent}
        </GridFilter>
      </>
    );
  }
  return (
    <div style={styles.loader}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default CustomGrid;

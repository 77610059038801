import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import Button from 'components/Button';
import { useModal } from 'components/Modals';
import { toastWarning } from 'utils/toast';

const TipoModal = ({ onSubmit }) => {
  const { closeModal } = useModal();
  const [value, setValue] = useState({
    especie_id: null,
    natureza_operacao_id: null,
  });

  const onConfirm = () => {
    if (Boolean(value?.especie_id) && Boolean(value?.natureza_operacao_id)) {
      onSubmit(`/${value?.especie_id}/${value?.natureza_operacao_id}`);
      closeModal();
    } else {
      toastWarning('Preencha todos os campos');
    }
  };

  const handleChange = (value, key) => {
    setValue((prev) => ({ ...prev, [key]: Number(value) }));
  };

  return (
    <>
      <DialogTitle>Espécie do Documento</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup
            value={value?.especie_id}
            onChange={(e) => handleChange(e?.target?.value, 'especie_id')}
          >
            <FormControlLabel
              value={10}
              control={<Radio />}
              label="Nota Fiscal"
            />
            <FormControlLabel value={25} control={<Radio />} label="Fatura" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogTitle>Remessa para Conserto ou Reparo</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup
            value={value?.natureza_operacao_id}
            onChange={(e) =>
              handleChange(e?.target?.value, 'natureza_operacao_id')
            }
          >
            <FormControlLabel value={93} control={<Radio />} label="Entrada" />
            <FormControlLabel value={39} control={<Radio />} label="Saída" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>Confirmar</Button>
        <Button onClick={closeModal} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default TipoModal;

import React from 'react';
import { Grid, InputAdornment, Badge } from '@mui/material';
import { FilterList, Search } from '@mui/icons-material';
import ButtonMenu from 'components/ButtonMenu';
import Button from 'components/Button';
import Input from 'components/Input';
import styles from './styles';

const GridToolbar = ({
  control,
  options,
  onSubmit,
  activeFilter,
  activeSearch = true,
  selection = [],
  showFilterButton,
  handleDrawerToggle,
}) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={8} sx={styles?.gridItem}>
        <ButtonMenu options={options} selection={selection} />
        {showFilterButton && (
          <Badge
            color="secondary"
            badgeContent={activeFilter}
            sx={styles?.button}
          >
            <Button
              size="small"
              aria-label="Mostrar Filtros"
              startIcon={<FilterList fontSize="medium" />}
              onClick={handleDrawerToggle}
            >
              Filtros
            </Button>
          </Badge>
        )}
      </Grid>
      <Grid item xs={12} sm={4} sx={styles?.gridItem}>
        {!!control && activeSearch && (
          <Input
            name="like"
            size="small"
            placeholder="Buscar..."
            control={control}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onKeyPress={(e) => {
              if (e?.key === 'Enter') {
                onSubmit();
              }
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default GridToolbar;

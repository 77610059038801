import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ExposicaoFeiraContext } from 'contexts/ExposiçãoFeiraContext';
import { RemessasContext } from 'contexts/RemessasContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import TipoModal from './Modals/TipoModal';
import Filter from './filter';
import mock from './mock';

const ExposiçãoFeira = () => {
  const rotina = 'RemessasExposiçãoFeira';
  const titulo = 'Exposições / Feiras';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { postRemessaCopia } = useContext(RemessasContext);
  const { exposicaoFeira, getExposicaoFeira, getLoading } = useContext(
    ExposicaoFeiraContext
  );
  const { control, getValues, reset } = useForm({
    defaultValues: exposicaoFeira?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getExposicaoFeira({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar',
      icon: 'add',
      action: () =>
        openModal(
          <TipoModal
            onSubmit={(url) => navigate(`/app/Remessas/Gerar${url}`)}
          />
        ),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({
          rotina,
          filter: getValues(),
          order: exposicaoFeira?.order,
        }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({
          rotina,
          filter: getValues(),
          order: exposicaoFeira?.order,
        }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={exposicaoFeira?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
  ];
  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.id}`),
    },
    {
      name: 'Copiar Remessa',
      icon: 'sync_problem',
      menu: true,
      action: ({ row }) =>
        postRemessaCopia({ data: { documento_id: row?.id }, cb: loadGrid }),
    },
    {
      name: 'Resumo da Remessa',
      icon: 'summarize',
      menu: true,
      action: ({ row }) => navigate(`/app/Remessas/Resumo/${row?.id}`),
    },
  ];

  return (
    <Container>
      <Header
        rotina={rotina}
        colunas={exposicaoFeira?.colunas}
        titulo={titulo}
      />
      <Card>
        <Grid
          grid={exposicaoFeira}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={exposicaoFeira} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default ExposiçãoFeira;
